<template>
  <div>
    <CRow>
      <CCol md="12" v-if="show">
        <CCard class="topCard">
          <CCardHeader class="topCardHeader">
            <div class="d-flex justify-content-between align-items-center">
              <div class="h4 m-0">Firma Komisyon Oranları</div>
              <div class="d-flex justify-content-end" style="min-width: 550px">
                <input
                  type="text"
                  v-model="searchFilterInput"
                  autocomplete="off"
                  class="form-control mr-3"
                  style="max-width: 250px"
                  placeholder="Arama..."
                  v-on:keyup="onFilterTextBoxChanged()"
                />
                <RoleProvider slug="VENDOR_COMMISSION_CREATE">
                  <div slot-scope="{ canItPass }">
                    <CButton
                      color="light"
                      variant="outline"
                      size="sm"
                      @click="createModalOpen"
                      class="py-2 px-3 font-weight-bold"
                      v-if="canItPass"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'plus']"
                        class="mr-2"
                      />Yeni Ekle
                    </CButton>
                  </div>
                </RoleProvider>
              </div>
            </div>
          </CCardHeader>

          <CCardBody class="p-0">
            <!-- :sendToClipboard="sendToClipboard" -->
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="list"
              :sideBar="sideBar"
              rowSelection="multiple"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
            ></ag-grid-vue>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :title="title"
      size="lg"
      :closeOnBackdrop="false"
      :show.sync="modal"
      class="modalNoBorder"
    >
      <validation-observer ref="observer">
        <CCard class="p-0 m-0 cardShadow">
          <CCardBody>
            <CContainer class="p-0 m-0">
              <CRow class="mb-4">
                <CCol>
                  <validation-provider
                    mode="eager"
                    name="Tedarikçi"
                    rules="required"
                  >
                    <label for="vendor">Tedarikçi Firma</label>
                    <div>
                      <v-select
                        v-model="form.vendorSlug"
                        :options="items.vendors"
                        :reduce="(item) => item.brandSlug"
                        placeholder="Seçiniz"
                        label="brand"
                        id="vendor"
                      />
                    </div>
                  </validation-provider>
                </CCol>
                <CCol>
                  <validation-provider
                    mode="eager"
                    name="Durum"
                    rules="required"
                  >
                    <label for="vendor">Durum</label>
                    <div>
                      <v-select
                        v-model="form.status"
                        :options="[
                          { value: 'active', status: 'Aktif' },
                          { value: 'passive', status: 'Pasif' },
                        ]"
                        :reduce="(item) => item.value"
                        placeholder="Seçiniz"
                        label="status"
                        id="vendor"
                      />
                    </div>
                  </validation-provider>
                </CCol>
              </CRow>
              <CRow class="mb-2">
                <CCol col="6">
                  <validation-provider
                    mode="eager"
                    name="Sözleşme Başlangıç"
                    rules="required"
                  >
                    <label for="startDate">Sözleşme Başlangıç</label>
                    <VueCtkDateTimePicker
                      only-date
                      no-header
                      no-button
                      no-label
                      auto-close
                      formatted="ll"
                      format="YYYY-MM-DD"
                      v-model="form.startDate"
                      locale
                      label="Seçiniz"
                    />
                  </validation-provider>
                </CCol>
                <CCol col="6">
                  <validation-provider
                    mode="eager"
                    name="Sözleşme Bitiş"
                    rules="required"
                  >
                    <label for="endDate">Sözleşme Bitiş</label>
                    <VueCtkDateTimePicker
                      only-date
                      no-header
                      no-button
                      no-label
                      auto-close
                      formatted="ll"
                      format="YYYY-MM-DD"
                      v-model="form.endDate"
                      :minDate="form.startDate"
                      locale
                      label="Seçiniz"
                    />
                  </validation-provider>
                </CCol>
              </CRow>
              <CRow
                v-for="(rate, index) in form.rates"
                :key="index"
                class="align-items-center"
              >
                <CCol col="3">
                  <h6>{{ rate.name.TR }}</h6>
                </CCol>
                <CCol col="9">
                  <CRow>
                    <CCol col="3" class="pr-1">
                      <CInput
                        label="Saatlık"
                        placeholder="0"
                        v-model="rate.hourly"
                        type="number"
                        max="100"
                        min="0"
                        prepend="%"
                      />
                    </CCol>
                    <CCol col="3" class="px-1">
                      <CInput
                        label="Günlük"
                        placeholder="0"
                        v-model="rate.daily"
                        type="number"
                        max="100"
                        min="0"
                        prepend="%"
                      />
                    </CCol>
                    <CCol col="3" class="px-1">
                      <CInput
                        label="Aylık"
                        placeholder="0"
                        v-model="rate.monthly"
                        type="number"
                        max="100"
                        min="0"
                        prepend="%"
                      />
                    </CCol>
                    <CCol col="3" class="pl-1">
                      <CInput
                        label="Uzun Dönem"
                        placeholder="0"
                        v-model="rate.long"
                        type="number"
                        max="100"
                        min="0"
                        prepend="%"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol col="12">
                  <hr class="my-0 mb-1" />
                </CCol>
              </CRow>
            </CContainer>
          </CCardBody>
        </CCard>
      </validation-observer>
      <template #footer>
        <RoleProvider slug="VENDOR_COMMISSION_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="process === 'post' && canItPass"
              :disabled="disabled"
              @click="created"
              >Ekle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_COMMISSION_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="danger"
              v-if="process === 'put' && canItPass"
              :disabled="disabled"
              @click="deleted(id)"
              >Sil</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_COMMISSION_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="process === 'put' && canItPass"
              :disabled="disabled"
              @click="updated(id)"
              >Düzenle</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
// ? Node modules.
import Vue from "vue";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
const _ = require("lodash");

// ? Components
import { AgGridVue } from "ag-grid-vue";
import vSelect from "vue-select";
import { DatePicker } from "v-calendar";
import { RoleProvider } from "../../provider";

// ? Css.
import "vue-select/dist/vue-select.css";

// ? System usings.
axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

// ? Environmets.
const { VUE_APP_API_URL } = process.env;

export default {
  name: "VendorRate",
  components: {
    AgGridVue,
    vSelect,
    "v-date-picker": DatePicker,
    RoleProvider,
  },
  data() {
    return {
      // AG GRID
      searchFilterInput: "",
      show: true,
      modal: false,
      disabled: false,
      rates: [],
      list: [],
      title: "Firma Komisyon Oranı Ekleme",
      process: "post",
      id: "",
      form: {
        vendorSlug: "",
        status: "",
        productType: "",
        rates: [],
        startDate: "",
        endDate: "",
      },
      items: {
        vendors: [],
        productTypes: [],
      },
      columnDefs: [
        {
          field: "validity",
          headerName: "Geçerlilik",
          width: 100,
          suppressSizeToFit: true,
          cellRenderer: (params) => {
            return params.value === "active" ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value == "active"
              ? { color: "#00c520", fontWeight: "bold" }
              : { color: "#c55300", fontWeight: "bold" };
          },
        },
        {
          field: "status",
          headerName: "Durum",
          width: 100,
          suppressSizeToFit: true,
          cellRenderer: (params) => {
            return params.value === "active" ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value == "active"
              ? { color: "#00c520", fontWeight: "bold" }
              : { color: "#c55300", fontWeight: "bold" };
          },
        },
        {
          field: "vendor_brand",
          headerName: "Tedarikçi",
          width: 300,
        },
        {
          cellRenderer: (params) => {
            if (params.data.validity == "passive") return "Geçersiz zaman.";

            var startDate = moment();
            var endDate = moment(params.data.endDate);
            var result =
              endDate.diff(startDate, "days") > 0
                ? endDate.diff(startDate, "days") + " Gün"
                : "Bitti";
            return result;
          },
          headerName: "Kalan Süre",
          width: 150,
        },
        {
          headerName: "Aralığı",
          width: 150,
          cellRenderer: (params) => {
            return `${moment(params.data.startDate).format("L")} ~ ${moment(
              params.data.endDate
            ).format("L")}`;
          },
        },
        {
          field: "createdAt",
          headerName: "Oluşturma Tarihi",
          width: 110,
          cellRenderer: (date) => moment(date).format("L"),
        },
        {
          field: "updatedAt",
          headerName: "Değiştirme Tarihi",
          width: 110,
          cellRenderer: (date) => moment(date).format("L"),
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
    };
  },
  watch: {
    modal: function (value) {
      if (value) {
        this.itemsVendor();
        this.itemsProductType();
      } else {
        this.modalCleaner();
      }
    },
  },

  asyncComputed: {
    async getList() {
      try {
        let { data: commissionRateFindAll } = await axios.get(
          `${VUE_APP_API_URL}admin/vendor/commission-rate`
        );
        const { data: vendorFindAll } = await axios.get(
            `${VUE_APP_API_URL}vendor`
          ),
          { vendorList } = vendorFindAll;

        _.forEach(commissionRateFindAll, (item) => {
          let vendor = _.find(vendorList, {
            brandSlug: item.vendorSlug,
          });
          item.vendor_brand = vendor ? vendor.brand : null;
          item.status = item.status ? "active" : "passive";
          item.validity = item.validity ? "active" : "passive";
        });

        this.list = _.reverse(commissionRateFindAll);

        let productTypeFindAll = await axios.get(
          `${VUE_APP_API_URL}product-type`
        );

        this.rates = productTypeFindAll.data.data;
      } catch (error) {}
    },
  },

  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },
    createModalOpen() {
      this.process = "post";
      this.title = "Komisyon Oranı Ekle";
      this.form.rates = this.rates;
      this.modal = true;
    },

    async itemsVendor() {
      try {
        let vendors = await axios.get(`${VUE_APP_API_URL}vendor`);
        this.items.vendors = vendors.data.vendorList;
      } catch (error) {
        console.error(error);
      }
    },

    async itemsProductType() {
      try {
        let vendors = await axios.get(`${VUE_APP_API_URL}product-type`);
        this.items.productTypes = vendors.data.data;
        this.items.productTypes.map(function (x) {
          return (x.label = x.name.TR);
        });
      } catch (error) {
        console.error(error);
      }
    },

    async created() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        var html = "";
        _.forEach(this.$refs.observer.errors, (value, key) => {
          value != ""
            ? (html +=
                "<p><strong>" + key + " alanı : </strong>" + value + "</p>")
            : "";
        });
        Vue.swal({
          icon: "error",
          title: "Formu Kontrol Edin",
          html,
          confirmButtonText: "Tamam",
        });
        return;
      }

      try {
        await axios.post(`${VUE_APP_API_URL}admin/vendor/commission-rate`, {
          vendorSlug: this.form.vendorSlug,
          status: this.form.status == "active",
          startDate: new Date(this.form.startDate).toISOString(),
          endDate: new Date(this.form.endDate).toISOString(),
          rates: this.form.rates,
        });
        this.$store.dispatch({
          type: "successSwal",
          text: "Komisyon oranı başarılı bir şekilde eklendi",
          refresh: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        console.error(error);
        let messages = [];
        Array.from(error.response.data.errors).forEach((item) =>
          messages.push(`<p>${item.message}</p>`)
        );
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          html: messages.join(""),
        });
      }
    },

    async updated(id) {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        var html = "";
        _.forEach(this.$refs.observer.errors, (value, key) => {
          value != ""
            ? (html +=
                "<p><strong>" + key + " alanı : </strong>" + value + "</p>")
            : "";
        });
        Vue.swal({
          icon: "error",
          title: "Formu Kontrol Edin",
          html,
          confirmButtonText: "Tamam",
        });
        return;
      }

      try {
        await axios.put(
          process.env.VUE_APP_API_URL + "admin/vendor/commission-rate/" + id,
          {
            vendorSlug: this.form.vendorSlug,
            status: this.form.status == "active",
            startDate: new Date(this.form.startDate).toISOString(),
            endDate: new Date(this.form.endDate).toISOString(),
            rates: this.form.rates,
          }
        );
        this.$store.dispatch({
          type: "successSwal",
          text: "Komisyon oranı başarılı bir şekilde düzenlendi",
          refresh: false,
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        console.error(error);
        let messages = [];
        Array.from(error.response.data.errors).forEach((item) =>
          messages.push(`<p>${item.message}</p>`)
        );
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          html: messages.join(""),
        });
      }
    },

    deleted(id) {
      Vue.swal({
        icon: "question",
        title: "Dikkat",
        text: "Komisyon oranını silmek istediğinize emin misiniz?",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.delete(
              `${VUE_APP_API_URL}admin/vendor/commission-rate/${id}`
            );
            this.$store.dispatch({
              type: "successSwal",
              text: "Komisyon oranı başarılı bir şekilde silindi",
              refresh: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } catch (error) {
            console.error(error);
            Vue.swal.fire({
              icon: "error",
              showCloseButton: true,
              text: error.response.data.errors[0].message,
            });
          }
        }
      });
    },

    onGridReady(params) {
      // ? Grid tablo hazır olduğunda çalışcak kodlar.
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    modalCleaner() {
      this.form.vendorSlug = "";
      this.form.startDate = "";
      this.form.endDate = "";
      this.form.status = "";
      this.form.rates = [];
    },

    onRowDoubleClicked(params) {
      this.process = "put";
      this.title = "Komisyon Oranı Güncelle";
      this.id = params.node.data._id;
      this.form.vendorSlug = params.node.data.vendorSlug;
      this.form.rates = params.node.data.rates;
      this.form.startDate = params.node.data.startDate;
      this.form.endDate = params.node.data.endDate;
      this.form.status = params.node.data.status;
      this.modal = true;
    },
  },
};
</script>
